<template>
    <Select v-model="addressModel"
            ref="select"
            @focus="focus"
            @input="getListTimeout"
            @listClick="clearList"
            itemsKey="value"
            :beforeList="warning || beforeList"
            label="value"
            :showLoading="showLoading"
            :title="title"
            :error="error"
            :list="list"/>
</template>

<script>
import Select from '@/components/form/select/Select'

import axios from 'axios'

const DADATA_URL = process.env.VUE_APP_DADATA_URL + 'address'
const TOKEN = process.env.VUE_APP_DADATA_TOKEN

export default {
    name: 'AddressSearch',
    model: {
        prop: 'model',
        event: 'change'
    },
    props: {
        title: {
            type: String,
            default: 'Адрес'
        },
        model: {
            type: String
        },
        error: {
            type: String,
            default: ''
        },
        to: {
            type: [String, Boolean],
            default: true
        }
    },
    mounted() {
        this.isMounted = true
    },
    data() {
        return {
            list: [],
            warning: '',
            timeoutID: null,
            showLoading: 0,
            isMounted: false
        }
    },
    computed: {
        beforeList() {
            if (!this.isMounted)
                return

            if (!this.addressModel) {
                if (this.$refs.select && this.$refs.select.fieldModel) {
                    return 'Выберите один из предложенных вариантов:'
                } else {
                    return 'Начните писать и выберите один из предложенных вариантов'
                }
            }

            return ''
        },
        addressModel: {
            get() {
                return {
                    value: this.model
                }
            },
            set(v) {
                this.$emit('change', v.value)
            }
        },
    },
    watch: {
        model() {
            this.list = []
        },
    },
    methods: {
        clearList() {
            this.list = []
        },
        focus(v) {
            if (this.addressModel || (!this.addressModel && this.list && this.list.length > 0))
                return

            this.getList(v)
        },
        getListTimeout(v) {
            this.$emit('change', v)
            this.warning = ''
            this.list = []
            this.showLoading = 5
            clearTimeout(this.timeoutID)
            this.timeoutID = setTimeout(() => this.getList(v), 600)
        },
        getList(v) {
            if (!v) {
                this.list = []
                this.showLoading = 0
                return
            }

            const data = {
                count: 5,
                restrict_value: true,
                query: v
            }

            axios.post(DADATA_URL, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${TOKEN}`
                }
            }).finally(() => {
                this.showLoading = 0
            }).then((response) => {
                if (response.data && response.data.suggestions && response.data.suggestions.length > 0) {
                    this.list = response.data.suggestions
                } else {
                    this.list = []
                }

                if (this.list.length === 0)
                    this.warning = 'Нет вариантов удовлетворяющих запросу'
            })
        }
    },
    components: {
        Select
    }
}
</script>
