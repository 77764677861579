<template>
    <div
        class="switch"
        :class="classes"
    >
        <p v-if="title"
           class="switch__title">
            {{ title }}
        </p>
        <div class="switch__wrapper">
            <ul class="switch__list">
                <li v-for="item in list"
                    class="switch__list-element"
                    :class="{
                        'switch__active': popupModel === item.key
                    }"
                    :key="item.key">
                    <label class="switch__label">
                        <span class="switch__input">
                            <input v-model="popupModel"
                                   :value="item.key"
                                   type="radio"/>
                        </span>
                        {{ item.value }}
                    </label>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ZSwitch',
    model: {
        prop: 'model',
        event: 'change'
    },
    props: {
        list: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            default: ''
        },
        model: {
            required: true
        },
        error: {
            type: String,
            default: ''
        }
    },
    computed: {
        popupModel: {
            get() {
                return this.model
            },
            set(v) {
                this.$emit('change', v)
            }
        },
        classes: {
            get() {
                return {
                    'switch_error': this.error
                }
            }
        }
    },
}
</script>

<style lang="scss">
@import "switch";
</style>
